.main-container{
    border-radius: 5px;
    padding: 15px;
    background: var(--primary);
    font-weight: bold;
}

.vehicle-info{
    background-color: var(--secundary);
    padding: 15px;
    border-radius: 10px;
    &__img-container{
        height: 200px;
        width: 100%;
        margin: 0 auto;
        &__img{
            width: 100%;
            height: 100%;
        }
    }
    &__data{
        padding: 5px 0;
        li{
            padding: 5px 0;
        }
    }
}

.left-container{
    border-right: 1px solid var(--secundary) ;
}

.right-container{
    display: flex; align-items: center; flex-direction: row; justify-content: space-between;
}

.mini-card{
    border: 1px solid var(--fontPrimaryColor);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
   // width: 70px;
   // height: 60px;
}

.vehicle-actions{
    li{
        margin: 10px 0;
    }
}

/*#show-menu mat-button-toggle-group {
    box-shadow: none;
}
*/
#show-menu mat-button-toggle {
    //  border: 1px rgba(0,0,0,0) solid !important;
    //  z-index: 3 !important;
      background-color: var(--secundary);
  }

  /*#show-menumat-button-toggle:hover {
      background-color: #2d4055 !important;
  }*/

 /* #show-menu .mat-button-toggle-checked {
      box-shadow: 0 0 30px #000 !important;
      background-color: #2d4055 !important;
  }*/

/*  #show-menu mat-button-toggle{
    background-color: #1f2937 !important;
  }*/
